import { CountryCode } from 'interfaces/Country'
import {
    legalContactRoute,
    legalGuidelinesRoute,
    legalImprintRoute,
    legalPrivacyRoute,
    legalTOSKnowerRoute,
    legalTOSRoute,
} from './routes'

type LegalTexts =
    | 'common/footerLegalImprintLink'
    | 'common/footerLegalPrivacyLink'
    | 'common/footerLegalTermsOfServicesLink'
    | 'common/footerLegalGuidelinesLink'
    | 'common/footerLegalTermsOfServicesKnowerLink'
    | 'common/footerLegalExpansionContestLink'
    | 'common/cancellationPolicy'
    | 'common/footerLegalContact'

export interface CountryConfig {
    payoutLimit: number
    enableMultipleGradesSelect: boolean
    showNavbarDownloadLink: boolean
    showNavbarKnowLibraryMenuItem: boolean
    showKnowContentConversionBanner: boolean
    showNavbarKnowDemandsMenuItem: boolean
    hasParentsPages: boolean
    hasContentCampaignRunning: boolean
    hasKnowExamBoards: boolean
    enableMicrosoftClarifyPageWide: boolean
    hasFacebookMessengerWidget: boolean
    legalLinks: {
        path: string
        text: LegalTexts
    }[]
    showKnowGrades: boolean
    showKnowSchoolTypes: boolean
    showCookieBanner: boolean
    hasPlusPlan: boolean
    allowImagesUpload?: boolean
    showExpansionCompetitionHasEndedMessage: boolean
    showReferralCodeModal: boolean
    hideHomepageFAQ: boolean
    hasCustomCustomerStorePage?: boolean
    hasStudyGuides?: boolean
    unblurContentForSmallerMarkets: boolean
    hasBlog?: boolean
}

export function getCountryConfig(countryCode: CountryCode): CountryConfig {
    switch (countryCode) {
        case CountryCode.Germany:
        case CountryCode.Austria:
        case CountryCode.Switzerland:
            return {
                payoutLimit: 50000,
                enableMultipleGradesSelect: true,
                hasParentsPages: true,
                showNavbarDownloadLink: true,
                showNavbarKnowLibraryMenuItem: true,
                showKnowContentConversionBanner: true,
                showNavbarKnowDemandsMenuItem: true,
                hasKnowExamBoards: false,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: false,
                hasFacebookMessengerWidget: false,
                hasCustomCustomerStorePage: true,
                unblurContentForSmallerMarkets: false,
                legalLinks: [
                    {
                        path: legalImprintRoute,
                        text: 'common/footerLegalImprintLink',
                    },
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: true,
                showCookieBanner: true,
                allowImagesUpload: false,
                hasPlusPlan: true,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }
        case CountryCode.France:
            return {
                payoutLimit: 10000,
                enableMultipleGradesSelect: true,
                hasParentsPages: false,
                showNavbarDownloadLink: true,
                showNavbarKnowLibraryMenuItem: true,
                showKnowContentConversionBanner: true,
                showNavbarKnowDemandsMenuItem: true,
                hasKnowExamBoards: false,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: false,
                hasFacebookMessengerWidget: false,
                hasCustomCustomerStorePage: true,
                unblurContentForSmallerMarkets: false,
                legalLinks: [
                    {
                        path: legalImprintRoute,
                        text: 'common/footerLegalImprintLink',
                    },
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: true,
                showCookieBanner: true,
                allowImagesUpload: false,
                hasPlusPlan: true,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }

        case CountryCode.Poland:
            return {
                payoutLimit: 50000,
                enableMultipleGradesSelect: false,
                hasParentsPages: false,
                showNavbarDownloadLink: true,
                showNavbarKnowLibraryMenuItem: true,
                showKnowContentConversionBanner: true,
                showNavbarKnowDemandsMenuItem: true,
                hasKnowExamBoards: false,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: false,
                hasFacebookMessengerWidget: false,
                unblurContentForSmallerMarkets: false,
                legalLinks: [
                    {
                        path: legalImprintRoute,
                        text: 'common/footerLegalImprintLink',
                    },
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: true,
                showCookieBanner: true,
                allowImagesUpload: false,
                hasPlusPlan: true,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }
        case CountryCode.Italy:
            return {
                payoutLimit: 50000,
                enableMultipleGradesSelect: false,
                hasParentsPages: false,
                showNavbarDownloadLink: true,
                showNavbarKnowLibraryMenuItem: false,
                showKnowContentConversionBanner: false,
                showNavbarKnowDemandsMenuItem: true,
                hasKnowExamBoards: false,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: false,
                hasFacebookMessengerWidget: false,
                unblurContentForSmallerMarkets: false,
                legalLinks: [
                    {
                        path: legalImprintRoute,
                        text: 'common/footerLegalImprintLink',
                    },
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: true,
                showCookieBanner: true,
                allowImagesUpload: false,
                hasPlusPlan: true,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }
        case CountryCode.UnitedKingdom:
            return {
                payoutLimit: 50000,
                enableMultipleGradesSelect: false,
                hasParentsPages: false,
                showNavbarDownloadLink: true,
                showNavbarKnowLibraryMenuItem: false,
                showKnowContentConversionBanner: false,
                showNavbarKnowDemandsMenuItem: false,
                hasKnowExamBoards: true,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: false,
                hasFacebookMessengerWidget: false,
                unblurContentForSmallerMarkets: true,
                legalLinks: [
                    {
                        path: legalImprintRoute,
                        text: 'common/footerLegalImprintLink',
                    },
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: false,
                showCookieBanner: true,
                allowImagesUpload: false,
                hasPlusPlan: false,

                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }
        case CountryCode.UnitedStates:
            return {
                payoutLimit: 50000,
                enableMultipleGradesSelect: false,
                hasParentsPages: false,
                showNavbarDownloadLink: true,
                showNavbarKnowLibraryMenuItem: false,
                showKnowContentConversionBanner: false,
                showNavbarKnowDemandsMenuItem: true,
                hasKnowExamBoards: false,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: true,
                hasFacebookMessengerWidget: true,
                unblurContentForSmallerMarkets: true,
                legalLinks: [
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },

                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: false,
                showCookieBanner: false,
                allowImagesUpload: true,
                hasPlusPlan: false,
                hasStudyGuides: true,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: true,
                hideHomepageFAQ: true,
                hasBlog: true,
            }
        case CountryCode.Spain:
            return {
                payoutLimit: 50000,
                enableMultipleGradesSelect: false,
                hasParentsPages: false,
                showNavbarDownloadLink: true,
                showNavbarKnowLibraryMenuItem: false,
                showKnowContentConversionBanner: false,
                showNavbarKnowDemandsMenuItem: false,
                hasKnowExamBoards: false,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: false,
                hasFacebookMessengerWidget: false,
                unblurContentForSmallerMarkets: true,
                legalLinks: [
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: false,
                showCookieBanner: true,
                allowImagesUpload: false,
                hasPlusPlan: false,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }
        case CountryCode.Colombia:
            return {
                payoutLimit: 130000000,
                enableMultipleGradesSelect: false,
                hasParentsPages: false,
                showNavbarDownloadLink: true,
                showNavbarKnowLibraryMenuItem: false,
                showKnowContentConversionBanner: false,
                showNavbarKnowDemandsMenuItem: false,
                hasKnowExamBoards: false,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: true,
                hasFacebookMessengerWidget: false,
                unblurContentForSmallerMarkets: true,
                legalLinks: [
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: false,
                showCookieBanner: false,
                allowImagesUpload: false,
                hasPlusPlan: false,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }
        case CountryCode.Turkey:
            return {
                payoutLimit: 600000,
                enableMultipleGradesSelect: false,
                hasParentsPages: false,
                showNavbarDownloadLink: false,
                showNavbarKnowLibraryMenuItem: false,
                showKnowContentConversionBanner: false,
                showNavbarKnowDemandsMenuItem: false,
                hasKnowExamBoards: true,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: true,
                hasFacebookMessengerWidget: false,
                unblurContentForSmallerMarkets: true,
                legalLinks: [
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: false,
                showCookieBanner: false,
                allowImagesUpload: false,
                hasPlusPlan: false,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }
        case CountryCode.Mexico:
            return {
                payoutLimit: 4300,
                enableMultipleGradesSelect: false,
                hasParentsPages: false,
                showNavbarDownloadLink: false,
                showNavbarKnowLibraryMenuItem: false,
                showKnowContentConversionBanner: false,
                showNavbarKnowDemandsMenuItem: false,
                hasKnowExamBoards: false,
                hasContentCampaignRunning: false,
                enableMicrosoftClarifyPageWide: true,
                hasFacebookMessengerWidget: false,
                unblurContentForSmallerMarkets: true,
                legalLinks: [
                    {
                        path: legalPrivacyRoute,
                        text: 'common/footerLegalPrivacyLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/footerLegalTermsOfServicesLink',
                    },
                    {
                        path: legalGuidelinesRoute,
                        text: 'common/footerLegalGuidelinesLink',
                    },
                    {
                        path: legalTOSKnowerRoute,
                        text: 'common/footerLegalTermsOfServicesKnowerLink',
                    },
                    {
                        path: legalTOSRoute,
                        text: 'common/cancellationPolicy',
                    },
                    {
                        path: legalContactRoute,
                        text: 'common/footerLegalContact',
                    },
                ],
                showKnowGrades: true,
                showKnowSchoolTypes: false,
                showCookieBanner: false,
                allowImagesUpload: false,
                hasPlusPlan: false,
                showExpansionCompetitionHasEndedMessage: false,
                showReferralCodeModal: false,
                hideHomepageFAQ: false,
            }
    }
}
